import {closeSnackBar, handleChangeLogin, loginFailure, loginRequestMade, loginSuccess} from './LoginAction';
import {
    clientRequestMade,
    closeSnackBarForClient,
    deleteClientFailure,
    deleteClientSuccess,
    fetchClientFailure,
    fetchClientSuccess,
    fetchSingleClientSuccess,
    fetchSingleClientFailure,
    handleChangeClient,
    searchClientFailInClientPage,
    searchClientSuccessInClientPage,
    setClientToEdit,
    updateClientFailure,handleChangeEmail1
} from "./ClientAction";
import {
    caseRequestMade,
    closeSnackBarForCase,
    deleteCaseFailure,
    deleteCaseSuccess,
    fetchCaseFailure,
    fetchCaseSuccess,
    getSingleClientInfo,
    handleChangeCase,
    handleChangeCaseAgent,
    makeChangeWorkFlowAgents,
    searchCaseFail,
    searchCaseSuccess,
    setCaseToEdit,
    updateCaseFailure,
    updateCaseSuccess
} from "./CaseAction";
import {
    addUserFailure,
    addUserSuccess,
    closeSnackBarForUser,
    deleteUserFailure,
    deleteUserSuccess,
    fetchUserFailure,
    fetchUserSuccess,
    handleChangeForUserImage,
    setUserRoleTabToEdit,
    updateUserRoleFailure,
    updateUserRoleSuccess,
    userRequestMade
} from "./UserAction";
import {
    closeSnackBarForWorkflow,
    deleteWorkflowFailure,
    deleteWorkflowSuccess,
    fetchWorkflowFailure,
    fetchWorkflowSuccess,
    workflowRequestMade
} from "./WorkflowAction";
import {
    assignProfilePictureFailure,
    assignProfilePictureSuccess,
    changePasswordFailure,
    changePasswordSuccess,
    closeSnackBarForProfile,
    fetchProfileSucess,
    handleChange,
    handleChangeForChangePassword,
    profileRequestMade,
    updateProfileFailure,
    updateProfilePictureFailure,
    updateProfilePictureSuccess,
    updateProfileSuccess
} from "./ProfileAction";
import {
    addWorkflowFailure,
    addWorkflowSuccess,
    closeSnackBarForWorkflowForm,
    fetchActionFailure,
    fetchActionSuccess,
    setWorkflowToEdit,
    workflowFormRequestMade
} from "./WorkflowFormAction";
import {
    addCaseTemplateFailure,
    addCaseTemplateSuccess,
    caseTemplateRequestMade,
    closeSnackBarForCaseTemplate,
    deleteCaseTemplateFailure,
    deleteCaseTemplateSuccess,
    fetchCaseTemplateFailure,
    fetchCaseTemplateSuccess,
    handleChangeCaseTemplate,
    setCaseTemplateToEdit
} from "./CaseTemplateAction";
import {
    apiRequestMadeForResetPassword,
    changeFirstPasswordFailure,
    changeFirstPasswordSuccess,
    closeSnackBarResetPasswordScreen,
    fetchUserInfoSuccess,
    handleChangeForResetPassword
} from "./ResetPasswordAction";
import {
    actionRequestMade,
    addActionFailure,
    addActionSuccess,
    closeSnackBarForAction,
    deleteActionFailure,
    deleteActionSuccess,
    fetchActionListFailure,
    fetchActionListSuccess,
    handleChangeAction,
    setActionToEdit
} from "./ActionTemplateAction";
import {
    addClientFailure,
    addClientSuccess,
    closeSnackBarForRegisterCase,
    fetchClientFailureInRegisterCase,
    fetchClientSuccessInRegisterCase,
    handleChangeCaseInRegisterCase,
    handleChangeClientInRegisterCase,
    registerCaseFailure,
    registerCaseRequestMade,
    registerCaseSuccess,
    searchClientFail,
    searchClientSuccess,
    setAddSucessFullToFalse
} from "./RegisterCaseAction";
import {
    addDynamicActionDataFailure,
    addDynamicActionDataSuccess,
    dynamicActionRequestMade,
    fetchActionInfoSuccess,
    handleChangeDynamicActionData,
    setDynamicActionDataToEdit,
    setPreviousTrue,
} from "./DynamicPageAction";

import {
    addCorporateFailure,
    addCorporateSuccess,
    closeSnackBarForCorporate,
    corporateRequestMade,
    deleteCorporateFailure,
    deleteCorporateSuccess,
    fetchCorporateFailure,
    fetchCorporateSuccess,
    handleChangeCorporateToAdd,
    handleChangeCorporateToEdit,
    setCorporateToAddNull,
    setCorporateToEdit
} from "./CorporateAction";

import {
    addCorporateClientFailure,
    addCorporateClientSuccess,
    assignClientToCorporateFailure,
    assignClientToCorporateSuccess,
    closeSnackBarForCorporateClient,
    corporateClientRequestMade,
    deleteCorporateClientFailure,
    deleteCorporateClientSuccess,
    fetchClientFailureInCorporateClient,
    fetchClientSuccessInCorporateClient,
    fetchCorporateInfoFailure,
    fetchCorporateInfoSuccess,
    handleChangeCorporateClient,
    searchClientBySsnFail,
    searchClientBySsnSuccess
} from "./CorporateClientAction";

import {scheduleTransactionRequestMade,
    fetchScheduleTransactionFailure,
    fetchScheduleTransactionSuccess,
    addNewScheduleTransactionFailure,
    addNewScheduleTransactionSuccess,
    handleChangeScheduleDetails,
    handleChangeOtherScheduleTransaction,
    handleChangeStatus,
    setScheduleTransactionToEdit,
    searchScheduleTransactionSuccess,
    searchScheduleTransactionFailure,
    closeScheduleTransactionSnackBar,
    editScheduleTransactionFailure,
    editScheduleTransactionSuccess,
    searchClientListFailure,
    searchClientListSuccess,
} from "./ScheduleTransactionAction";

import{
    vendorRequestMade,
    fetchVendorFailure,
    fetchVendorSuccess,
    addNewVendorFailure,
    addNewVendorSuccess,
    handleChangeVendor,
    closeVendorSnackBar,
    searchVendorSuccess,
    searchVendorFailure,
    deleteVendorSuccess,
    deleteVendorFailure,
} from "./VendorAction";

import{
    validateUrlSuccess,
    validateUrlFailure
} from "./DynamicClientPageAction";

import {setPasswordSuccess, setPasswordFailure, handleChangeEmail} from "./SetPasswordAction";

import {fetchCardFailure, setCardToView, updateCardStatusSuccess,updateCardStatusFailure,updateOrderStatusFailure,updateOrderStatusSuccess ,fetchCardSuccess,cardRequestMade, handleChangeClientId,handleChangeOrderStatus, handleChangeCardStatus} from "./CardAction";
import {
    fetchDataForCalendarByClientSuccess,
    fetchDataForCalendarByAgentFailure,
    fetchDataForCalendarByAgentSuccess,
    fetchDataForCalendarByClientFailure,
    detailsForCalendarRequestMade,
    fetchDataForCalendarFailure,
    fetchDataForCalendarSuccess
}
from "./CalendarAction";
import {
    fetchUpcomingTransactionFailure,
    fetchUpcomingTransactionSuccess,
    upComingTransactionRequestMade,
    processTransactionFailure,
    processTransactionSuccess,
} from "./UpComingTransactionAction";
export {
    fetchUpcomingTransactionSuccess,
    fetchUpcomingTransactionFailure,
    upComingTransactionRequestMade,
    processTransactionSuccess,
    processTransactionFailure,
}

export {
    fetchDataForCalendarByClientFailure,
    fetchDataForCalendarByClientSuccess,
    fetchDataForCalendarByAgentFailure,
    fetchDataForCalendarByAgentSuccess,
    detailsForCalendarRequestMade,fetchDataForCalendarFailure,
    fetchDataForCalendarSuccess
}
export {
    validateUrlFailure,
    validateUrlSuccess,
}
export {loginRequestMade,loginFailure, handleChangeLogin, loginSuccess, closeSnackBar};
export {
    fetchClientSuccess,
    fetchClientFailure,
    closeSnackBarForClient,
    deleteClientFailure,
    deleteClientSuccess,
    clientRequestMade,
    handleChangeClient,
    setClientToEdit,
    updateClientFailure,
    searchClientSuccessInClientPage,
    searchClientFailInClientPage,
    fetchSingleClientSuccess,
    fetchSingleClientFailure,
    handleChangeEmail1
};
export {
    fetchCaseSuccess,
    fetchCaseFailure,
    closeSnackBarForCase,
    deleteCaseSuccess,
    deleteCaseFailure,
    caseRequestMade,
    handleChangeCase,
    setCaseToEdit,
    getSingleClientInfo,
    makeChangeWorkFlowAgents,
    handleChangeCaseAgent,
    updateCaseFailure,
    updateCaseSuccess,
    searchCaseSuccess,
    searchCaseFail
};
export {
    fetchUserSuccess,
    fetchUserFailure,
    closeSnackBarForUser,
    userRequestMade,
    addUserSuccess,
    addUserFailure,
    handleChangeForUserImage,
    updateUserRoleSuccess,
    updateUserRoleFailure,
    deleteUserSuccess,
    deleteUserFailure,
    setUserRoleTabToEdit
};
export {
    fetchWorkflowSuccess,
    workflowRequestMade,
    closeSnackBarForWorkflow,
    fetchWorkflowFailure,
    deleteWorkflowFailure,
    deleteWorkflowSuccess
};
export {
    fetchProfileSucess,
    handleChange,
    profileRequestMade,
    changePasswordFailure,
    changePasswordSuccess,
    closeSnackBarForProfile,
    updateProfilePictureFailure,
    updateProfilePictureSuccess,
    updateProfileFailure,
    updateProfileSuccess,
    assignProfilePictureFailure,
    assignProfilePictureSuccess,
    handleChangeForChangePassword
};
export {
    workflowFormRequestMade,
    fetchActionSuccess,
    fetchActionFailure,
    closeSnackBarForWorkflowForm,
    addWorkflowSuccess,
    addWorkflowFailure,
    setWorkflowToEdit
};
export {
    fetchCaseTemplateSuccess,
    caseTemplateRequestMade,
    closeSnackBarForCaseTemplate,
    deleteCaseTemplateFailure,
    deleteCaseTemplateSuccess,
    fetchCaseTemplateFailure,
    addCaseTemplateFailure,
    addCaseTemplateSuccess,
    handleChangeCaseTemplate,
    setCaseTemplateToEdit
};
export {
    handleChangeForResetPassword,
    changeFirstPasswordFailure,
    apiRequestMadeForResetPassword,
    closeSnackBarResetPasswordScreen,
    fetchUserInfoSuccess,
    changeFirstPasswordSuccess
};
export {
    addActionFailure,
    fetchActionListSuccess,
    fetchActionListFailure,
    deleteActionSuccess,
    deleteActionFailure,
    handleChangeAction,
    closeSnackBarForAction,
    addActionSuccess,
    actionRequestMade,
    setActionToEdit
};
export {
    handleChangeClientInRegisterCase,
    registerCaseRequestMade,
    addClientFailure,
    addClientSuccess,
    closeSnackBarForRegisterCase,
    handleChangeCaseInRegisterCase,
    registerCaseFailure,
    registerCaseSuccess,
    searchClientFail,
    searchClientSuccess,
    fetchClientFailureInRegisterCase,
    fetchClientSuccessInRegisterCase,
    setAddSucessFullToFalse
}
export {
    addDynamicActionDataFailure,
    fetchActionInfoSuccess,
    addDynamicActionDataSuccess,
    handleChangeDynamicActionData,
    setDynamicActionDataToEdit,
    setPreviousTrue,
    dynamicActionRequestMade,
};

export {
    fetchCorporateFailure,
    addCorporateFailure,
    addCorporateSuccess,
    closeSnackBarForCorporate,
    corporateRequestMade,
    deleteCorporateFailure,
    deleteCorporateSuccess,
    fetchCorporateSuccess,
    handleChangeCorporateToAdd,
    handleChangeCorporateToEdit,
    setCorporateToEdit,
    setCorporateToAddNull
}

export {
    fetchCorporateInfoFailure,
    closeSnackBarForCorporateClient,
    corporateClientRequestMade,
    deleteCorporateClientFailure,
    deleteCorporateClientSuccess,
    fetchCorporateInfoSuccess,
    addCorporateClientFailure,
    addCorporateClientSuccess,
    handleChangeCorporateClient,
    assignClientToCorporateFailure,
    assignClientToCorporateSuccess,
    searchClientBySsnSuccess,
    searchClientBySsnFail,
    fetchClientFailureInCorporateClient,
    fetchClientSuccessInCorporateClient
}

export {
    scheduleTransactionRequestMade,
    fetchScheduleTransactionSuccess,
    fetchScheduleTransactionFailure,
    addNewScheduleTransactionFailure,
    addNewScheduleTransactionSuccess,
    handleChangeScheduleDetails,
    handleChangeOtherScheduleTransaction,
    handleChangeStatus,
    setScheduleTransactionToEdit,
    searchScheduleTransactionFailure,
    searchScheduleTransactionSuccess,
    closeScheduleTransactionSnackBar,
    editScheduleTransactionFailure,
    editScheduleTransactionSuccess,
    searchClientListFailure,
    searchClientListSuccess
}

export {
    fetchVendorSuccess,
    fetchVendorFailure,
    addNewVendorSuccess,
    addNewVendorFailure,
    vendorRequestMade,
    handleChangeVendor,
    searchVendorSuccess,
    searchVendorFailure,
    closeVendorSnackBar,
    deleteVendorSuccess,
    deleteVendorFailure
}
export {
    fetchCardSuccess,
    fetchCardFailure,
    cardRequestMade,
    handleChangeClientId,
    handleChangeOrderStatus,
    handleChangeCardStatus,
    updateOrderStatusFailure,
    updateOrderStatusSuccess,
    updateCardStatusSuccess,
    updateCardStatusFailure,
    setCardToView
}
export {
    setPasswordFailure,
    setPasswordSuccess,
    handleChangeEmail,
}